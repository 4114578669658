.navbar-container {
	position: absolute;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	width: 600px;
	display: flex;
	border-radius: 15px;
	overflow: hidden;
}

.navbar-item {
	background-color: #e1e1e1;
	display: block;
	flex: 1;
	text-align: center;
	text-decoration: none;
	color: #242424;
    padding: 10px 0;
    font-family: Arial, Helvetica, sans-serif;
}

.navbar-active {
    background-color: #3d3d3d;
    color: #ffffff;
    font-weight: bold;
}

@media (max-width: 600px) {
	.navbar-container {
		width: 100vw;
	}
}
