* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.login-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #f7f7f7;
}

.login-btn {
    text-align: center;
    color: #ffffff;
    background-color: #415A77;
    border: none;
    outline: none;
    font-size: 20px;
    padding: 20px 70px;
    border-bottom: 5px solid rgb(46, 68, 94);
    align-self: stretch;
}

.login-btn:active {
    border-bottom: 0px solid;
}

.currency-input {
    box-sizing: border-box;
    padding:10px 10px ;
    font-size: 20px;
    align-self: stretch;
    border: 1px solid #e1e1e1;
    margin-bottom: 20px;
}

.currency-input:focus {
    outline: none;
}

.item-contaienr {
    width: 20rem;
    display: flex;
    flex-direction: column;
}

