.cancel-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgb(241, 66, 75);
}
.cancel-container p {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
}