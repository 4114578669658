.transaction-container {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
    background-color: #f7f7f7;
    
}
.transaction-tabel-container {
	width: 40rem;
	overflow-x: auto;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border: 1px solid #ddd;
}

th,
td {
	text-align: left;
    padding: 8px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
}

tr:nth-child(even) {
	background-color: #e1e1e1;
}
tr:nth-child(odd) {
	background-color: #ffffff;
}


@media (max-width: 650px) {
    .transaction-tabel-container {
        width: 35rem;
    }
}

@media (max-width: 550px) {
    .transaction-tabel-container {
        width: 35rem;
    }
    
    .transaction-tabel-container td {
        width: 30rem;
        font-size: 10px;
    }
}