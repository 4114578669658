.success-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgb(3, 167, 112);
}
.success {
    font-size: 25px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: 900;
    color: #ffffff;
}